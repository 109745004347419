/* global angular _ kendo */
import { formPermission } from '../modules/main/form/services/FormPermissions'
import { mapOrder } from 'app/helper'
const debug = require('debug')('nextplus:form-utils')
const statusColorHelper = require('../../../../common/services/status-color-helper')
const constantFields = require('../../../../common/constants/constantFields')
const formColumns = require('../../../../common/constants/form-columns.js')

/** @ngInject */
function FormUtils (
  $rootScope,
  $mdDialog,
  $mdToast,
  $translate,
  FieldUtilsService,
  UserUtils,
  Form,
  FormData,
  UserModel,
  htmlWork,
  FormlyHelper,
  KendoGridHelper,
  $mdMedia,
  PermissionUtils,
  DateTimeFormatService,
  KendoDataSourceHelper
) {
  const editableFieldTypes = [
    'select',
    'checkbox',
    'input',
    'radio',
    'button',
    'upload',
    'textarea',
    'datePicker',
    'dateTimePicker',
    'gpsInput',
    'selectUser',
    'tinymce'
  ]

  const defaultValues = {
    name: '',
    icon: null,
    singleton: false,
    global: false,
    sendNotificationEmail: true,
    hasStatus: false,
    removeAssignee: false,
    unassigned: false,
    enableInWorkflowPreview: false,
    enableAsStandalone: true,
    requireDeviceLink: true,
    context: 'session',
    defaultStatus: 'FORM.STATUS.OPEN',
    closeStatusOnWorkflowRelease: 'FORM.STATUS.CLOSE',
    statuses: [
      {
        name: 'FORM.STATUS.OPEN',
        status: 'open',
        color: '#c3c3c3'
      },
      {
        name: 'FORM.STATUS.IN_PROGRESS',
        status: 'in_progress',
        color: '#ff7f27'
      },
      {
        name: 'FORM.STATUS.CLOSE',
        status: 'close',
        color: '#b5e61d'
      }
    ],
    viewCertificate: [],
    createCertificate: [],
    closeCertificate: [],
    defaultAssignee: [],
    approvalWorkflows: [],
    presaveTriggers: [],
    postsaveTriggers: [],
    fieldSettings: {},
    includeInDigestEmail: true,
    viewForDigestEmail: null,
    weight: null
  }

  const handleTrigger = async function handleTrigger (
    action,
    formDataObject,
    submitForApproval
  ) {
    switch (action.principleType) {
      case 'sendSMS':
      case 'sendEmail':
      case 'showInWorkflow':
      case 'assignForm':
      case 'webhook':
        try {
          await FormData.handleTrigger({
            data: action,
            formDataId: formDataObject.id,
            submitForApproval: submitForApproval || false
          }).$promise
        } catch (err) {
          const {
            data: {
              error: { message }
            }
          } = err
          $mdToast.updateTextContent(`${$translate.instant(message)}`)
          $mdToast.show(
            $mdToast.nextplus({
              position: $rootScope.toastLocation,
              parent: 'document.body',
              theme: 'error-toast',
              hideDelay: 3000,
              content: `${$translate.instant(message)}`
            })
          )
        }
        break
    }
  }

  const performActions = async function performActions (
    actions,
    formDataObject,
    submitForApproval
  ) {
    debug('Perform Form Trigger Actions', actions)
    return new Promise(async (resolve, reject) => {
      if (actions.length === 0) return null
      const actionsResults = []
      for (let i = 0; i < actions.length; i++) {
        const action = actions[i]
        switch (action.principleType) {
          case 'validation':
            actionsResults.push({
              type: 'validation',
              validationType: action.principleId,
              message: action.value
            })
            break
          default:
            await handleTrigger(action, formDataObject, submitForApproval)
        }
      }
      if (actionsResults.length === 0) resolve()
      else {
        $mdDialog
          .show({
            /** @ngInject */
            controller: ($scope, $mdDialog) => {
              $scope.cancel = () => $mdDialog.cancel()
              $scope.skip = () => $mdDialog.hide(true)
              $scope.hasError = actionsResults.find(
                v => v.validationType === 'error'
              )
              $scope.validations = actionsResults.map(v => ({
                icon:
                  v.validationType === 'warning' ? 'icon-alert' : 'icon-cancel',
                color: v.validationType === 'warning' ? '#ff9800' : '#f44336',
                message: v.message
              }))
            },
            template: `<md-dialog data-testid="form-validation-dialog" style="min-width:80rem;">
                            <md-toolbar>
                                <div class="md-toolbar-tools">
                                    <h2 translate="FORM.TRIGGERS_DIALOG.VALIDATIONS_ALERTS"></h2>
                                    <span flex></span>
                                    <md-button class="md-icon-button" ng-click="cancel()">
                                        <md-icon md-font-icon="icon-close" aria-label="Close dialog"></md-icon>
                                    </md-button>
                                </div>
                            </md-toolbar>
                            <md-dialog-content style="height: 100%;">
                                <div layout="column" layout-align="center start">
                                    <div layout="row" layout-align="start center" ng-repeat="validation in validations track by $index">
                                        <md-icon md-font-icon="{{validation.icon}}" ng-style="{color: validation.color }" style="margin: 1rem;"></md-icon>
                                        <h3>{{validation.message}}</h3>
                                    </div>
                                </div>
                            </md-dialog-content>
                            <md-dialog-actions>
                                <md-button ng-click="cancel()" class="md-raised md-warn" data-testid="cancel">
                                    <span translate="BUTTONS.CANCEL"></span>
                                </md-button>
                                <md-button md-autofocus class="md-raised md-primary" ng-click="skip()" ng-if="!hasError" data-testid="skip">
                                    <span translate="BUTTONS.SKIP"></span>
                                </md-button>
                            </md-dialog-actions>
                          </md-dialog>`,
            parent: angular.element(document.body),
            targetEvent: '',
            multiple: true,
            escapeToClose: true,
            fullscreen: true,
            clickOutsideToClose: false
          })
          .then(
            function (res) {
              resolve()
            },
            function () {
              reject() //eslint-disable-line
            }
          )
      }
    })
  }

  const isPermit = function isPermit (permissionType, form) {
    return formPermission(
      permissionType,
      form,
      $rootScope.currentUser.certificateIds,
      $rootScope.currentUser.roles.map(r => r.id)
    )
  }

  const isPermitForReview = async function isPermitForReview (
    form,
    formData,
    levelId
  ) {
    const { ownerId, assignee } = formData
    const approvalWorkflowId = formData.approvalWorkflow.id
    if (formData.approvalWorkflow.status !== 'PENDING') return false
    const approvalWorkflow = form.approvalWorkflows.find(
      approvalWorkflow => approvalWorkflow.id === approvalWorkflowId
    )
    if (approvalWorkflow) {
      const level = approvalWorkflow.levels.find(level => level.id === levelId)
      if (level) {
        const userIds = await UserUtils.normalizeUserPicker(
          level.userIds,
          ownerId,
          { assignee }
        )
        if (userIds.includes($rootScope.currentUser.id)) {
          const formDataLevel = formData.approvalWorkflow.levels.find(
            level => level.id === levelId
          )
          if (formDataLevel) {
            return !formDataLevel.approvedBy.find(
              approvedObj => approvedObj.userId === $rootScope.currentUser.id
            )
          }
          return false
        }
        return false
      }
      return false
    }
    return false
  }
  const generateSubFormTemplate = function generateSubFormTemplate (
    controllerScope,
    kendoPath,
    columnId,
    subFormKey,
    data,
    innerFields,
    dbFields,
    usersNamesById
  ) {
    let htmlValue = '--'
    if (
      _.isNil(data) ||
      _.isNil(data.fields) ||
      _.isNil(data.fields[subFormKey])
    ) {
      return htmlValue
    }
    const kendoGridInstance = _.get(controllerScope, kendoPath)
    const kendoInstance = kendoGridInstance.instance

    const tableColumns = kendoInstance
      ? kendoInstance.columns.filter(c => !c.hidden)
      : kendoGridInstance.gridOptions.columns.filter(c => !c.hidden)
    if (kendoInstance && !tableColumns.find(c => c.uniqueId === columnId)) {
      return htmlValue
    }
    const subFormMapping =
      KendoGridHelper.createComplexTypeMapping(tableColumns)
    const currentSequence = subFormMapping.find(c => c.columnId === columnId)
    if (!currentSequence) {
      return htmlValue
    }
    const relevantInnerFields = tableColumns
      .slice(
        currentSequence.index,
        currentSequence.index + currentSequence.length
      )
      .map(c => {
        const parts = c.uniqueId.split('_')
        return parts[parts.length - 1]
      })
    innerFields = mapOrder(
      innerFields.filter(f => relevantInnerFields.includes(f.id)),
      relevantInnerFields,
      'id'
    )
    const values = data.fields[subFormKey]
    if (values && values.length) {
      htmlValue = '<div layout="column" layout-align="center start">'
      htmlValue += `<table class="sub-form-display-table"><thead><tr>
                      <th><span>#</span></th>`
      for (let i = 0; i < innerFields.length; i++) {
        const field = innerFields[i]
        htmlValue += `<th><span>${htmlWork.htmlEncode(field.title)}</span></th>`
      }
      htmlValue += '</tr></thead><tbody>'
      for (let idx = 0; idx < values.length; idx++) {
        const row = values[idx]
        htmlValue += `<tr><td>${idx + 1}</td>`
        for (let f = 0; f < innerFields.length; f++) {
          const innerField = innerFields[f]
          const innerFieldValue = row[innerField.id + '_lookup']
            ? row[innerField.id + '_lookup']
            : row[innerField.id]
          const html = FieldUtilsService.getFieldHTMLValue(
            dbFields,
            innerField.id,
            innerFieldValue,
            usersNamesById,
            'column',
            false
          )
          htmlValue += `<td dir="auto">${!_.isNil(html) ? html : ''}</td>`
        }
        htmlValue += '</tr>'
      }
      htmlValue += '</tbody></table></div>'
    }
    return htmlValue
  }

  const generateIBFTemplate = function generateIBFTemplate (
    controllerScope,
    kendoPath,
    columnId,
    IBFkey,
    data,
    innerFields,
    dbFields,
    usersNamesById
  ) {
    let htmlValue = '--'
    if (_.isNil(data) || _.isNil(data.fields) || _.isNil(data.fields[IBFkey])) {
      return htmlValue
    }
    const kendoGridInstance = _.get(controllerScope, kendoPath)
    const kendoInstance = kendoGridInstance.instance

    const tableColumns = kendoInstance
      ? kendoInstance.columns.filter(c => !c.hidden)
      : kendoGridInstance.gridOptions.columns.filter(c => !c.hidden)
    if (kendoInstance && !tableColumns.find(c => c.uniqueId === columnId)) {
      return htmlValue
    }
    const IBFMapping = KendoGridHelper.createComplexTypeMapping(tableColumns)
    const currentSequence = IBFMapping.find(c => c.columnId === columnId)
    if (!currentSequence) {
      return htmlValue
    }
    const relevantInnerFields = tableColumns
      .slice(
        currentSequence.index,
        currentSequence.index + currentSequence.length
      )
      .map(c => c.uniqueId.split('_')[1])
    innerFields = mapOrder(
      innerFields.filter(f => relevantInnerFields.includes(f.id)),
      relevantInnerFields,
      'id'
    )
    const fieldData = data.fields[IBFkey]
    if (fieldData && fieldData.length) {
      htmlValue = '<div layout="column" layout-align="center start">'
      htmlValue += `<table class="image-based-form-display-table"><thead><tr>
                      <th><span>${$translate.instant(
                        'MAP_EDITOR.LAYER'
                      )}</span></th>`
      for (let i = 0; i < innerFields.length; i++) {
        const field = innerFields[i]
        htmlValue += `<th><span>${htmlWork.htmlEncode(field.title)}</span></th>`
      }
      htmlValue += '</tr></thead><tbody>'
      for (let idx = 0; idx < fieldData.length; idx++) {
        const row = fieldData[idx]
        const { values, areaName } = row
        htmlValue += `<tr><td>${htmlWork.htmlEncode(areaName)}</td>`
        for (let f = 0; f < innerFields.length; f++) {
          const innerField = innerFields[f]
          const innerFieldValue =
            innerField.type === 'lookupSelect'
              ? values.find(
                  valueObj => valueObj.fieldId === innerField.id + '_lookup'
                )
              : values.find(valueObj => valueObj.fieldId === innerField.id)
          const html = FieldUtilsService.getFieldHTMLValue(
            dbFields,
            innerField.id,
            innerFieldValue && !_.isNil(innerFieldValue.value)
              ? innerFieldValue.value
              : null,
            usersNamesById,
            'column',
            false
          )
          htmlValue += `<td dir="auto">${!_.isNil(html) ? html : ''}</td>`
        }
        htmlValue += '</tr>'
      }
      htmlValue += '</tbody></table></div>'
    }
    return htmlValue
  }

  const getInnerFieldType = function getInnerFieldType (fieldObject) {
    switch (fieldObject.type) {
      case 'input':
        return fieldObject.subtype === 'number' ? 'number' : 'string'
      case 'checkbox':
        return 'boolean'
      case 'datePicker':
      case 'dateTimePicker':
        return 'date'
      default:
        return 'string'
    }
  }

  const generateFieldsColumns = function generateFieldsColumns (
    form,
    dbFields,
    controllerScope,
    users,
    kendoPath = 'kendoGrid'
  ) {
    const columns = []
    const dbFieldsById = _.keyBy(dbFields, 'id')
    const usersNamesById =
      users?.length > 0
        ? users.reduce((obj, key) => {
            obj[key.id] = key.displayName
            return obj
          }, {})
        : {}
    for (let i = 0; i < form.fields.length; i++) {
      const field = form.fields[i]
      const existsField = dbFieldsById[field.fieldId]
      switch (existsField.type) {
        case 'imageBasedForm':
          {
            const innerFields = dbFields.filter(f =>
              existsField.fieldIds.includes(f.id)
            )
            const orderedFields = mapOrder(
              _.uniqBy(innerFields, 'id'),
              existsField.fieldIds,
              'id'
            )
            for (let i = 0; i < orderedFields.length; i++) {
              const innerField = orderedFields[i]
              const id = `${field.id}_${innerField.id}`
              columns.push({
                uniqueId: id,
                field: `fields["${field.id}"]["${innerField.id}"]`,
                translateCode: `(${existsField.title}) ${innerField.title}`,
                type: getInnerFieldType(innerField),
                filterable: !FieldUtilsService.nonFilterableTypes.includes(
                  innerField.type
                ),
                trustedTemplate: data => {
                  return generateIBFTemplate(
                    controllerScope,
                    kendoPath,
                    id,
                    field.id,
                    data,
                    orderedFields,
                    dbFields,
                    usersNamesById
                  )
                }
              })
            }
          }
          break
        case 'subForm':
          {
            const innerFields = dbFields.filter(f =>
              existsField.fieldIds.includes(f.id)
            )
            const orderedFields = mapOrder(
              _.unionBy(innerFields, 'id'),
              existsField.fieldIds,
              'id'
            )
            for (let i = 0; i < orderedFields.length; i++) {
              const innerField = orderedFields[i]
              const id = `${field.id}_${innerField.id}`
              columns.push({
                uniqueId: id,
                field: `fields["${field.id}"]["${innerField.id}"]`,
                translateCode: `(${existsField.title}) ${innerField.title}`,
                type: getInnerFieldType(innerField),
                filterable: !FieldUtilsService.nonFilterableTypes.includes(
                  innerField.type
                ),
                trustedTemplate: data => {
                  return generateSubFormTemplate(
                    controllerScope,
                    kendoPath,
                    id,
                    field.id,
                    data,
                    orderedFields,
                    dbFields,
                    usersNamesById
                  )
                }
              })
            }
          }
          break
        case 'gpsInput':
          columns.push({
            uniqueId: field.id,
            field: `fields["${field.id}"]`,
            translateCode: existsField.title,
            type: 'object',
            attributes: {
              'field-id': field.id
            },
            filterable: false,
            sortable: false,
            trustedTemplate: data => {
              return !data.fields || !data.fields[field.id]
                ? '--'
                : FieldUtilsService.getFieldHTMLValue(
                    [existsField],
                    field.fieldId,
                    data.fields[field.id]
                  )
            }
          })
          break
        case 'checkbox':
          columns.push({
            uniqueId: field.id,
            field: `fields["${field.id}"]`,
            translateCode: existsField.title,
            type: 'boolean',
            attributes: {
              'field-id': field.id
            },
            filterable: true
          })
          break

        case 'textarea':
          columns.push({
            uniqueId: field.id,
            field: `fields["${field.id}"]`,
            translateCode: existsField.title,
            type: 'string',
            attributes: {
              'field-id': field.id
            },
            filterable: true,
            trustedTemplate: data => {
              const value = data?.fields[field.id]
              if (value && value.length > 255) {
                const string = htmlWork.htmlEncode(value)
                return htmlWork.nl2br(string.substring(0, 255)) + '...'
              }
              return value ? htmlWork.nl2br(htmlWork.htmlEncode(value)) : '--'
            }
          })
          break
        case 'upload':
        case 'button':
          columns.push({
            uniqueId: field.id,
            field: `fields["${field.id}"]`,
            translateCode: existsField.title,
            type: 'array',
            attributes: {
              'field-id': field.id
            },
            sortable: false,
            sendToAi: false,
            trustedTemplate: data => {
              return !data.fields || !data.fields[field.id]
                ? ''
                : FieldUtilsService.getFieldHTMLValue(
                    [existsField],
                    field.fieldId,
                    data.fields[field.id]
                  )
            }
          })
          break
        case 'datePicker':
        case 'dateTimePicker':
          columns.push({
            uniqueId: field.id,
            field: `fields["${field.id}"]`,
            translateCode: existsField.title,
            type: 'date',
            attributes: {
              'field-id': field.id
            },
            filterable: true,
            sortable: true,
            trustedTemplate: data => {
              return !data.fields || !data.fields[field.id]
                ? '--'
                : FieldUtilsService.getFieldHTMLValue(
                    [existsField],
                    field.fieldId,
                    data.fields[field.id]
                  )
            }
          })
          break
        case 'lookupSelect':
          if (existsField.tableType === FieldUtilsService.lookupTypes.MODELS) {
            columns.push({
              uniqueId: field.id,
              field: `fields["${field.id}"]`,
              translateCode: existsField.title,
              filterable: true,
              sortable: true,
              template: data => {
                return _.isNil(data.fields[field.id])
                  ? '--'
                  : data.fields[field.id]
              }
            })
          } else if (existsField.table && existsField.table.columns) {
            for (let j = 0; j < existsField.table.columns.length; j++) {
              const column = existsField.table.columns[j]
              if (
                !column.deletedAt &&
                existsField.fieldIds.includes(column.id)
              ) {
                const id = `${field.id}_lookup_${column.id}`
                columns.push({
                  uniqueId: id,
                  field: `fields["${id}"]`,
                  translateCode: `${existsField.title} -> ${column.name}`,
                  type: column.type,
                  filterable: true,
                  sortable: true,
                  template: data => {
                    return _.isNil(data.fields[id]) ? '--' : data.fields[id]
                  }
                })
              }
            }
          }
          break
        case 'select':
        case 'radio':
          columns.push({
            uniqueId: field.id,
            field: `fields["${field.id}"]`,
            translateCode: existsField.title,
            filterable: true,
            sortable: true,
            attributes: {
              'field-id': field.id
            },
            trustedTemplate: data => {
              return !data.fields || !data.fields[field.id]
                ? '--'
                : FieldUtilsService.getFieldHTMLValue(
                    [existsField],
                    field.fieldId,
                    data.fields[field.id]
                  )
            }
          })
          break
        case 'selectUser':
          columns.push({
            uniqueId: field.id,
            field: `fields["${field.id}"]`,
            translateCode: existsField.title,
            filterable: {
              mode: 'row',
              cell: {
                showOperators: false,
                operator: 'eq',
                suggestionOperator: 'eq',
                template: function (args) {
                  args.element.kendoDropDownList({
                    filter: 'contains',
                    autoBind: false,
                    dataTextField: 'displayName',
                    dataValueField: 'id',
                    dataSource: new kendo.data.DataSource({
                      data: users
                    }),
                    valuePrimitive: true
                  })
                }
              }
            },
            sortable: true,
            type: 'array',
            attributes: {
              'field-id': field.id
            },
            trustedTemplate: data => {
              return !data.fields || !data.fields[field.id]
                ? '--'
                : FieldUtilsService.getFieldHTMLValue(
                    [existsField],
                    field.fieldId,
                    data.fields[field.id],
                    usersNamesById
                  )
            }
          })
          break
        case 'tinymce':
          columns.push({
            uniqueId: field.id,
            field: `fields["${field.id}"]`,
            translateCode: existsField.title,
            filterable: true,
            sortable: true,
            attributes: {
              'field-id': field.id
            },
            trustedTemplate: data => {
              return !data.fields || !data.fields[field.id]
                ? '--'
                : FieldUtilsService.getFieldHTMLValue(
                    [existsField],
                    field.fieldId,
                    data.fields[field.id]
                  )
            }
          })
          break
        default: {
          let type = 'string'
          if (existsField.subtype === 'number') {
            type = 'number'
          }
          columns.push({
            uniqueId: field.id,
            field: `fields["${field.id}"]`,
            translateCode: existsField.title,
            type,
            attributes: {
              'field-id': field.id
            },
            filterable: true,
            template: data => {
              return !_.isNil(data.fields[field.id])
                ? data.fields[field.id]
                : '--'
            }
          })
        }
      }
    }
    return columns
  }

  const getFailureFields = async function getFailureFields () {
    const failureFields = Object.values(constantFields)
    return failureFields
  }
  const convertFieldsFromDBToFormly =
    async function convertFieldsFromDBToFormly (
      formFields,
      fields,
      formInstance,
      newForm = false
    ) {
      const fieldsById = _.keyBy(fields, 'id')
      const filterFields = []
      const formlyFields = []
      formFields = _.cloneDeep(
        formFields.filter(ff => !ff.id.includes('lookup_'))
      )
      formFields.forEach(formField => {
        const field = _.cloneDeep(fieldsById[formField.fieldId])
        field.formId = formField.id
        field.display = formField.display
        filterFields.push(field)
      })
      const ids = formFields.map(field => field.id)
      const orderedFields = mapOrder(filterFields, ids, 'formId')
      // change ids
      formFields.forEach((field, idx) => {
        orderedFields[idx].id = field.id
      })
      for (let i = 0; i < orderedFields.length; i++) {
        const orderedField = orderedFields[i]
        if (orderedField.type === 'upload' || orderedField.type === 'button') {
          orderedField.parserInstantRun = !newForm
        }
        const formlyField = await FormlyHelper.convertToFormlyObject(
          orderedFields[i]
        )
        if (formlyField.type === 'subForm') {
          formlyField.templateOptions.overrideStateName = 'app.form.display'
          formlyField.templateOptions.layout = 'column'
          formlyField.templateOptions.editable = false
        }
        if (!newForm) {
          delete formlyField.defaultValue
        }
        formlyField.templateOptions.staticLabel = true
        formlyField.hideExpression = function ($viewValue, $modelValue, scope) {
          formInstance.createDisplayFieldsObject()
          if (orderedField.type === 'select' || orderedField.type === 'radio') {
            formlyField.templateOptions.options =
              FormlyHelper.manipulateOptions(
                scope.model.displayFieldsObject[orderedField.id].options,
                orderedFields[i].type
              )
          }
          return (
            scope?.model?.displayFieldsObject?.[orderedField.id]?.hide || false
          )
        }

        formlyFields.push(formlyField)
      }
      return formlyFields
    }

  /**
   * Convert object from database structure to formly structure
   * @param {object} form form structure
   * @param {array} fields fields in system - Field entity
   */
  const convertDBToForm = async function convertDBToForm (form, fields) {
    const meta = {}
    Object.keys(defaultValues).forEach(key => {
      meta[key] = !_.isNil(form[key]) ? form[key] : defaultValues[key]
      if (key === 'statuses') {
        meta[key] = meta[key].map(status => {
          if (status.name.indexOf('FORM.') !== -1) {
            return {
              name: $translate.instant(status.name),
              status: status.status,
              color: status.color
            }
          }
          return status
        })
      } else if (
        meta[key] &&
        typeof meta[key] === 'string' &&
        meta[key].indexOf('FORM.') !== -1
      ) {
        if (key === 'defaultStatus') {
          meta[key] = $translate.instant(meta[key])
        } else if (key === 'closeStatusOnWorkflowRelease') {
          meta[key] = $translate.instant(meta[key])
        }
      }
    })
    const filterFields = []
    const formFields = form.fields
    // fill filter fields in loop - dont use filter due to duplicate fields
    formFields.forEach(formField => {
      const field = _.cloneDeep(
        fields.find(field => field.id === formField.fieldId)
      )
      field.formId = formField.id
      filterFields.push(field)
    })
    const orderedFields = mapOrder(
      filterFields,
      formFields.map(field => field.id),
      'formId'
    )
    const formlyFields = []
    for (let i = 0; i < orderedFields.length; i++) {
      const formlyField = await FormlyHelper.convertToFormlyObject(
        orderedFields[i]
      )
      formlyFields.push(formlyField)
    }
    // change ids in formly
    formFields.forEach((field, idx) => {
      formlyFields[idx].key = field.id
      orderedFields[idx].formFieldId = field.id
    })
    return { meta, formlyFields, filterFields: orderedFields }
  }

  /**
   * Convert object from client form structure to database structure
   * @param {object} meta form meta - name,singleton etc..
   * @param {array} fields form fields
   */
  const convertFormToDB = function convertFormToDB (meta, fields) {
    const dbObj = {}
    Object.keys(defaultValues).forEach(key => {
      if (key === 'approvalWorkflows') {
        dbObj[key] = meta.approvalWorkflow
          ? [_.omit(meta.approvalWorkflow, 'triggerChange')]
          : defaultValues[key] // change value to array
      } else {
        dbObj[key] = !_.isNil(meta[key]) ? meta[key] : defaultValues[key]
      }
    })
    dbObj.fields = []
    const manipulatedFields = []
    for (let i = 0; i < fields.length; i++) {
      const formField = fields[i]
      const uniqueId = formField.formFieldId
      manipulatedFields.push({
        id: uniqueId,
        fieldId: formField.id
      })
    }
    dbObj.fields = manipulatedFields
    return dbObj
  }

  const checkForApprovalManagerError =
    async function checkForApprovalManagerError (formId, ownerId) {
      const form = await Form.findOne({
        filter: {
          where: { id: formId },
          fields: { id: true, approvalWorkflows: true }
        }
      }).$promise
      const user = await UserModel.findById({
        id: ownerId,
        filter: {
          fields: { id: true, displayName: true, manager: true }
        }
      }).$promise
      if (!form) return true
      if (form.approvalWorkflows && form.approvalWorkflows.length > 0) {
        const { levels } = form.approvalWorkflows[0] // FIXME: Change when start implement multiple approval workflows
        if (
          levels
            .filter(level => !level.deleted)
            .some(level => level.userIds.includes('manager')) &&
          (_.isNil(user.manager) || _.isEmpty(user.manager))
        ) {
          $mdToast.updateTextContent(
            $translate.instant('FORM.ERROR.APPROVAL_MANAGER_ERROR', {
              userName: user.displayName
            })
          )
          $mdToast.show(
            $mdToast.nextplus({
              position: $rootScope.toastLocation,
              parent: 'document.body',
              theme: 'error-toast',
              hideDelay: 6000
            })
          )
          return false
        }
      }
      return true
    }

  const generateFormTitle = function generateFormTitle (
    formData = {},
    formDatas = [],
    form = {},
    context = 'session'
  ) {
    const title = []
    if (form) {
      title.push(form.name)
    }
    const sessionDetails = []
    let serials = []
    if (formData?.stocks?.length > 0) {
      serials = formData.stocks?.map(stock => stock.serial)
    } else if (formData?.serials?.length > 0) {
      serials = formData?.serials
    }
    if (formDatas.length === 0 && serials.length > 0) {
      serials.forEach(serial => {
        sessionDetails.push({
          serial
        })
      })
    }
    if (context === 'session') {
      if (formData?.workorderNumber) {
        title.push(formData?.workorderNumber)
      }
      if (formData?.partSku) {
        title.push(formData?.partSku)
      }
      if (sessionDetails.length > 0) {
        title.push(sessionDetails.map(session => session.serial).join(', '))
      }
    }
    return title.join(' / ')
  }
  const generateStatusTemplate = function generateStatusTemplate (
    formData,
    status = null
  ) {
    try {
      if (status === null) {
        status = formData.status
      }
      let statusObject = formData.statuses.find(s => s.name === status)
      if (!statusObject) {
        statusObject = formData.statuses
          .map(status => {
            return {
              name: $translate.instant(status.name),
              status: status.status,
              color: status.color
            }
          })
          .find(s => s.name === status)
      }
      let backgroundColor, color
      if (statusObject) {
        backgroundColor = statusObject.color
        color = statusColorHelper.getReadableFontColor(backgroundColor)
      }
      return status
        ? `<div style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
              <span class="form-status" style="background-color:${htmlWork.escapeHTMLQuotes(
                backgroundColor
              )}; color:${color}">${htmlWork.htmlEncode(
            $translate.instant(status)
          )}</span>
            </div>`
        : '<span>--</span>'
    } catch (ex) {
      console.error(ex)
    }
  }

  const actionColumnTemplate = function actionColumnTemplate (
    data,
    state,
    options
  ) {
    const {
      canView,
      hasEditPermissions,
      canCreate,
      isMobileDevice,
      hasDeletePermissions
    } = options
    let html = ''
    const generateViewButton = function generateViewButton (
      data,
      extraButtonParams = [],
      extraIconParams = []
    ) {
      return `<md-button data-testid="edit-${htmlWork.htmlEncode(
        data.id
      )}" ${extraButtonParams.join(' ')}>
       <md-icon style="margin: 0.5em;" md-font-icon="icon-eye" class="s16" ${extraIconParams.join(
         ' '
       )}></md-icon>
              </md-button>
      `
    }
    const generateInlineEditButton = function generateInlineEditButton (data) {
      return `<md-button ng-class="($root.isTabletOrMobile) ? ['md-fab','md-mini','md-primary'] : 'md-icon-button'" ng-click="openFormForEdit($event,'${htmlWork.htmlEncode(
        data.id
      )}')" data-testid="edit-line-${htmlWork.htmlEncode(
        data.id
      )}" ng-disabled="$root.loadingProgress">
                <md-icon md-font-icon="icon-pencil" class="s16" ng-style="{'margin-top': $root.isTabletOrMobile ? 'auto' : '0em'}"></md-icon>
              </md-button>`
    }
    const generateDeleteButton = function generateDeleteButton (
      data,
      extraButtonParams
    ) {
      return `<md-button ng-class="($root.isTabletOrMobile) ? ['md-fab','md-mini'] : 'md-icon-button'" class="md-warn" ${extraButtonParams} data-testid="delete-${htmlWork.htmlEncode(
        data.id
      )}" ng-disabled="$root.loadingProgress">
      <md-icon md-font-icon="icon-delete" class="s16"></md-icon>
    </md-button>`
    }
    const generateInlineButtons = function generateInlineButtons (data) {
      return `<div layout="row" layout-align="start center" style="direction: ${
        $rootScope.dir
      }" ng-if="inlineEditObject && inlineEditObject.formDataId === '${htmlWork.htmlEncode(
        data.id
      )}'">
                <md-button class="md-icon-button" ng-disabled="$root.loadingProgress" ng-click="updateForm('${htmlWork.htmlEncode(
                  data.id
                )}')">
                  <md-tooltip>
                    <span translate="BUTTONS.UPDATE"></span>
                  </md-tooltip>
                  <md-icon md-font-icon="icon-content-save"></md-icon>
                </md-button>
                <md-button class="md-icon-button md-warn" ng-disabled="$root.loadingProgress" ng-click="closeEditForm()">
                  <md-tooltip>
                    <span translate="BUTTONS.CANCEL"></span>
                  </md-tooltip>
                  <md-icon md-font-icon="icon-close"></md-icon>
                </md-button>
              </div>`
    }
    switch (state) {
      case 'dialog':
        html += `<div layout="row" layout-align="start center">`
        if (canView)
          html += generateViewButton(data, [
            `ng-click="openForm('${htmlWork.htmlEncode(
              data.id
            )}','${htmlWork.htmlEncode(data.sessionId)}','${htmlWork.htmlEncode(
              data.stockId
            )}')"`,
            `class="edit-button md-icon-button"`,
            `style="padding-top: 0 !important;"`
          ])
        if (canCreate && hasDeletePermissions)
          html += generateDeleteButton(data, [
            `ng-click="deleteFormData('${htmlWork.htmlEncode(data.id)}')"`
          ])
        html += '</div>'
        break
      case 'medium':
        html += `<div layout="row" layout-align="start center">`
        if (canView)
          html += generateViewButton(data, [
            `ng-click="openForm('${htmlWork.htmlEncode(
              data.sessionId
            )}','${htmlWork.htmlEncode(data.formId)}','${htmlWork.htmlEncode(
              data.id
            )}','${htmlWork.htmlEncode(data.stockId)}')"`,
            `class="edit-button md-icon-button"`,
            `style="padding-top: 0 !important;"`
          ])
        if (canCreate && hasDeletePermissions)
          html += generateDeleteButton(data, [
            `ng-click="deleteFormData('${htmlWork.htmlEncode(
              data.id
            )}','${htmlWork.htmlEncode(data.formId)}')"`
          ])
        html += '</div>'
        break
      case 'multiForm':
        html += `<div layout="row" layout-align="start center" style="direction: ${$rootScope.dir}">`
        html += generateViewButton(
          data,
          [
            `ng-class="($root.isTabletOrMobile) ? ['md-fab','md-mini','md-primary'] : 'md-icon-button'"`,
            `ui-sref="app.form.display({id:'${htmlWork.htmlEncode(data.id)}'})"`
          ],
          [
            `ng-style="{'margin-top': $root.isTabletOrMobile ? 'auto' : '0.3em'}"`
          ]
        )
        if (hasDeletePermissions)
          html += generateDeleteButton(data, [
            `ng-click="deleteFormData('${htmlWork.htmlEncode(
              data.id
            )}','${htmlWork.htmlEncode(data.formId)}')"`
          ])
        html += '</div>'
        break
      case 'full':
        html = `<div layout="row" form-data-id="${htmlWork.htmlEncode(
          data.id
        )}" layout-align="start center" style="direction: ${
          $rootScope.dir
        }" ng-if="!inlineEditObject || inlineEditObject.formDataId !== '${htmlWork.htmlEncode(
          data.id
        )}'">`
        if (canView)
          html += generateViewButton(
            data,
            [
              `ui-sref="app.form.display({id:'${htmlWork.htmlEncode(
                data.id
              )}'})"`,
              `ng-class="($root.isTabletOrMobile) ? ['md-fab','md-mini','md-primary'] : 'md-icon-button'"`
            ],
            [
              `ng-style="{'margin-top': $root.isTabletOrMobile ? 'auto' : '0.3em'}"`
            ]
          )
        if (hasEditPermissions && canCreate && !isMobileDevice)
          html += generateInlineEditButton(data)
        if (canCreate && hasDeletePermissions)
          html += generateDeleteButton(data, [
            `ng-click="deleteFormData('${htmlWork.htmlEncode(data.id)}')"`
          ])
        html += '</div>'
        if (hasEditPermissions && canCreate) html += generateInlineButtons(data)
        break
    }

    return html
  }

  const generateAssigneeTemplate = function generateAssigneeTemplate (
    formData,
    userById,
    assignee = null
  ) {
    if (assignee === null) {
      assignee = formData.assignee
    }
    if (typeof assignee.toJSON === 'function') {
      assignee = assignee.toJSON()
    }
    if (Array.isArray(assignee) && assignee.length > 0) {
      return `<span>${assignee
        .map(userId =>
          userById[userId]
            ? htmlWork.htmlEncode(userById[userId].displayName)
            : ''
        )
        .join(', ')}</span>`
    }
    return '--'
  }

  const generateOwnerTemplate = function generateOwnerTemplate (
    formData,
    userById,
    owner = null
  ) {
    if (owner === null) {
      owner = formData.ownerId
    }
    if (owner && userById[owner]) {
      return htmlWork.htmlEncode(userById[owner].displayName)
    }
    return '--'
  }

  /**
   * auto generate form's fields as columns
   */
  // available states = ['dialog', 'full', 'medium', 'multiForm']
  const generateFormColumns = function generateFormColumns (
    state,
    form,
    fields,
    users,
    controllerScope,
    kendoPath = 'kendoGrid'
  ) {
    let canView = null
    let canCreate = null
    let statuses = null
    let approvalWorkflows = null
    if (state !== 'multiForm') {
      canView = isPermit('view', form)
      canCreate = isPermit('create', form)
      statuses = form.statuses
        ? form.statuses.map(status => ({
            value: status.name,
            label: status.name,
            color: status.color
          }))
        : []
      approvalWorkflows = form.approvalWorkflows
    }
    const hasDeletePermissions = PermissionUtils.isPermit(
      'FormData',
      'deleteById'
    )
    const hasEditPermissions = PermissionUtils.isPermit(
      'FormData',
      'patchAttributes'
    )
    const isMobileDevice = $mdMedia('xs') || $mdMedia('sm')
    const usersForViewFilter = users.map(user => {
      return { value: user.id, text: user.displayName }
    })
    const approvalStatuses = [
      { value: 'APPROVED', label: $translate.instant('FORM.STATUS.APPROVED') },
      { value: 'REJECTED', label: $translate.instant('FORM.STATUS.REJECTED') },
      { value: 'PENDING', label: $translate.instant('FORM.STATUS.IN_PROGRESS') }
    ]
    const userById = _.keyBy(users, 'id')
    const columns = [
      // ACTIONS
      {
        uniqueId: 'ae1992c6-7f8b-41e1-acb0-08eb8d1e8b68',
        field: 'id',
        translateCode: 'WF.ACTIONS',
        sortable: false,
        filterable: false,
        locked: !isMobileDevice,
        lockable: false,
        sendToAi: false,
        trustedTemplate: function (data) {
          return actionColumnTemplate(data, state, {
            canView,
            hasEditPermissions,
            canCreate,
            isMobileDevice,
            hasDeletePermissions
          })
        }
      },
      // NUMBER
      {
        uniqueId: formColumns.number.id,
        field: 'number',
        translateCode: formColumns.number.translateCode,
        type: 'number',
        filterable: true,
        lockable: false,
        trustedTemplate: data => {
          if (data.number) {
            if (['dialog', 'medium'].includes(state)) {
              const ngClick =
                state === 'dialog'
                  ? `openForm('${htmlWork.htmlEncode(
                      data.id
                    )}','${htmlWork.htmlEncode(
                      data.sessionId
                    )}','${htmlWork.htmlEncode(data.stockId)}')`
                  : `openForm('${htmlWork.htmlEncode(
                      data.sessionId
                    )}','${htmlWork.htmlEncode(
                      data.formId
                    )}','${htmlWork.htmlEncode(
                      data.id
                    )}','${htmlWork.htmlEncode(data.stockId)}')`
              return `<a style="cursor: pointer;"data-testid="show-${
                data.id
              }" ng-click="${ngClick}" >${htmlWork.htmlEncode(data.number)}</a>`
            } else {
              return `<a data-testid="show-${
                data.id
              }" ui-sref="app.form.display({id:'${
                data.id
              }'})">${htmlWork.htmlEncode(data.number)}</a>`
            }
          }
          return '--'
        }
      },
      // FORM NAME
      ...(state === 'multiForm'
        ? [
            {
              uniqueId: formColumns.formName.id,
              field: 'formName',
              translateCode: formColumns.formName.translateCode,
              type: 'string',
              filterable: true,
              sortable: true,
              template: data => {
                if (!data.formName) return '--'
                return data.formName
              }
            }
          ]
        : []),
      // WORKORDER NUMBER
      {
        uniqueId: formColumns.workorderNumber.id,
        field: 'workorderNumber',
        translateCode: formColumns.workorderNumber.translateCode,
        type: 'string',
        filterable: true,
        sortable: true,
        lockable: false
      },
      // SKU
      {
        uniqueId: formColumns.partSku.id,
        field: 'partSku',
        translateCode: formColumns.partSku.translateCode,
        lockable: false
      },
      // REV
      {
        uniqueId: formColumns.partRev.id,
        field: 'partRev',
        translateCode: formColumns.partRev.translateCode,
        lockable: false
      },
      // SERIAL
      {
        uniqueId: formColumns.serial.id,
        field: 'serial',
        translateCode: formColumns.serial.translateCode,
        type: 'string',
        filterable: true,
        sortable: false,
        lockable: false
      }
    ]
    if (!form || form?.requireDeviceLink) {
      columns.push(
        ...[
          // STOCK SKU
          {
            uniqueId: formColumns.stockSku.id,
            field: 'stockSku',
            translateCode: formColumns.stockSku.translateCode,
            filterable: true,
            sortable: false,
            lockable: false
          },
          // STOCK SERIAL
          {
            uniqueId: formColumns.stockSerial.id,
            field: 'stockSerial',
            translateCode: formColumns.stockSerial.translateCode,
            filterable: true,
            sortable: false,
            lockable: false
          },
          // STOCK LOT NUMBER
          {
            uniqueId: formColumns.stockLot.id,
            field: 'stockLot',
            translateCode: formColumns.stockLot.translateCode,
            filterable: true,
            sortable: false,
            lockable: false
          }
        ]
      )
    }
    columns.push(
      ...[
        // WORKFLOW_NAME
        {
          uniqueId: formColumns.workflowName.id,
          field: 'workflowName',
          hidden: form ? form.context !== 'workflow' : false,
          translateCode: formColumns.workflowName.translateCode,
          sortable: false,
          lockable: false
        },
        // NODE_NAME
        {
          uniqueId: formColumns.nodeName.id,
          field: 'nodeName',
          hidden: form ? form.context !== 'workflow' : false,
          translateCode: formColumns.nodeName.translateCode,
          lockable: false,
          trustedTemplate: data => {
            if (!data.nodeName) return '--'
            const nodeName = htmlWork.htmlEncode(data.nodeName)
            if (
              data.context === 'workflow' &&
              !_.isNil(data.workflowId) &&
              !_.isNil(data.originalNodeId)
            ) {
              return `<a data-cy="workflow-preview-${
                data.id
              }" ui-sref="app.workflow.session.show({preview:'${htmlWork.escapeString(
                data.originalNodeId
              )}', id: '${htmlWork.escapeString(data.workflowId)}'})">
                      ${nodeName}
                    </a>`
            }
            return nodeName
          }
        },
        // ACCOUNT_NAME
        {
          uniqueId: formColumns.accountName.id,
          field: 'accountName',
          translateCode: formColumns.accountName.translateCode,
          type: 'string',
          filterable: true,
          sortable: true,
          lockable: false,
          hidden: true
        },

        // PURCHASE_ORDER_NUMBER
        {
          uniqueId: formColumns.orderNumber.id,
          field: 'orderNumber',
          translateCode: formColumns.orderNumber.translateCode,
          type: 'string',
          filterable: true,
          sortable: true,
          lockable: false,
          hidden: true
        }
      ]
    )

    if (state !== 'multiForm') {
      const nodeModel = $rootScope.appSettings.modelsFields.Node
      const nodeProperties = nodeModel.properties
      _.mapKeys(nodeProperties, (value, key) => {
        if (value.custom) {
          columns.push({
            uniqueId: 'node_' + value.key,
            field: 'node_' + value.key,
            hidden: form.context !== 'workflow',
            lockable: false,
            translateCode: 'Node.' + value.key,
            width: '80px',
            filterable: true
          })
        }
      })
      columns.push(
        ...generateFieldsColumns(
          form,
          fields,
          controllerScope,
          users,
          kendoPath
        )
      )
    }
    if ((form && !form.removeAssignee) || state === 'multiForm') {
      // ASSIGNEE
      columns.push({
        uniqueId: formColumns.assignee.id,
        field: 'assignee',
        viewFilterType: 'string',
        translateCode: formColumns.assignee.translateCode,
        type: 'array',
        lockable: false,
        attributes: {
          'field-id': 'assignee'
        },
        values: usersForViewFilter,
        filterable: {
          mode: 'row',
          cell: {
            showOperators: false,
            template: function (args) {
              args.element.kendoDropDownList({
                filter: 'contains',
                autoBind: false,
                dataTextField: 'displayName',
                dataValueField: 'id',
                dataSource: new kendo.data.DataSource({
                  data: users
                }),
                valuePrimitive: true
              })
            }
          }
        },
        trustedTemplate: function (data) {
          return generateAssigneeTemplate(data, userById)
        }
      })
    }

    if (form?.hasStatus || state === 'multiForm') {
      // STATUS BY STATUS CODE
      columns.push({
        uniqueId: formColumns.status.id,
        field: 'status',
        translateCode: formColumns.status.translateCode,
        type: 'string',
        lockable: false,
        attributes: {
          'field-id': 'status'
        },
        filterable:
          state === 'multiForm'
            ? true
            : {
                mode: 'row',
                cell: {
                  showOperators: false,
                  template: function (args) {
                    args.element.kendoDropDownList({
                      filter: 'contains',
                      autoBind: false,
                      dataTextField: 'label',
                      dataValueField: 'value',
                      dataSource: new kendo.data.DataSource({
                        data: statuses
                      }),
                      index: 0,
                      optionLabel: {
                        label: $translate.instant('WF.ALL_STATUS'),
                        value: ''
                      },
                      valuePrimitive: true
                    })
                  }
                }
              },
        trustedTemplate: generateStatusTemplate
      })
      // CLOSED BY
      columns.push({
        uniqueId: formColumns.closedBy.id,
        field: 'closedBy',
        translateCode: formColumns.closedBy.translateCode,
        type: 'string',
        sortable: false,
        filterable: {
          mode: 'row',
          cell: {
            showOperators: false,
            template: function (args) {
              args.element.kendoDropDownList({
                filter: 'contains',
                autoBind: false,
                dataTextField: 'displayName',
                dataValueField: 'id',
                dataSource: new kendo.data.DataSource({
                  data: users
                }),
                valuePrimitive: true
              })
            }
          }
        },
        lockable: false,
        template: data => {
          if (data.closedBy && userById[data.closedBy]) {
            return userById[data.closedBy].displayName
          }
          return '--'
        }
      })
      // CLOSED AT
      columns.push({
        uniqueId: formColumns.closedAt.id,
        field: 'closedAt',
        translateCode: formColumns.closedAt.translateCode,
        type: 'date',
        filterable: true,
        lockable: false,
        template: data => {
          return !data.closedAt
            ? '--'
            : DateTimeFormatService.formatDateTime(data.closedAt, 'dateTime')
        }
      })
      // opened
      columns.push({
        uniqueId: formColumns.opened.id,
        field: 'opened',
        translateCode: formColumns.opened.translateCode,
        type: 'date',
        hidden: true,
        filterable: true,
        lockable: false,
        template: data => {
          return !data.opened
            ? '--'
            : DateTimeFormatService.formatDateTime(data.opened, 'dateTime')
        }
      })
      // DAYS_OPEN
      columns.push({
        uniqueId: formColumns.daysOpen.id,
        field: 'daysOpen',
        translateCode: formColumns.daysOpen.translateCode,
        type: 'number',
        hidden: true,
        filterable: true,
        lockable: false
      })
    }
    if (approvalWorkflows?.length > 0 || state === 'multiForm') {
      // Approval status
      columns.push({
        uniqueId: formColumns.approvalWorkflowStatus.id,
        field: 'approvalWorkflow.status',
        translateCode: formColumns.approvalWorkflowStatus.translateCode,
        type: 'string',
        filterable: {
          mode: 'row',
          cell: {
            showOperators: false,
            template: function (args) {
              args.element.kendoDropDownList({
                filter: 'contains',
                autoBind: false,
                dataTextField: 'label',
                dataValueField: 'value',
                dataSource: new kendo.data.DataSource({
                  data: approvalStatuses
                }),
                index: 0,
                valuePrimitive: true
              })
            }
          }
        },
        lockable: false,
        template: data => {
          let status = ''
          if (data.approvalWorkflow) {
            switch (data.approvalWorkflow.status) {
              case 'APPROVED':
                status = $translate.instant('FORM.STATUS.APPROVED')
                break
              case 'REJECTED':
                status = $translate.instant('FORM.STATUS.REJECTED')
                break
              case 'PENDING':
                status = $translate.instant('FORM.STATUS.IN_PROGRESS')
                break
            }
          }
          return status
        }
      })
    }

    // REPORTER
    columns.push({
      uniqueId: formColumns.ownerId.id,
      field: 'ownerId',
      translateCode: formColumns.ownerId.translateCode,
      sortable: false,
      viewFilterType: 'string',
      lockable: false,
      attributes: {
        'field-id': 'ownerId'
      },
      values: usersForViewFilter,
      filterable: {
        mode: 'row',
        cell: {
          operator: 'in',
          showOperators: false,
          suggestionOperator: 'in',
          template: function (args) {
            args.element.kendoMultiSelect({
              filter: 'contains',
              autoBind: false,
              dataTextField: 'displayName',
              dataValueField: 'id',
              dataSource: new kendo.data.DataSource({
                data: users
              }),
              valuePrimitive: true
            })
          }
        }
      },
      trustedTemplate: function (data) {
        return generateOwnerTemplate(data, userById)
      }
    })
    // CREATE DATE
    columns.push({
      uniqueId: formColumns.created.id,
      field: 'created',
      translateCode: formColumns.created.translateCode,
      type: 'date',
      filterable: true,
      lockable: false,
      template: data => {
        return data.created === null
          ? '--'
          : DateTimeFormatService.formatDateTime(data.created, 'dateTime')
      }
    })
    columns.push(
      ...[
        // LINKED TO
        {
          uniqueId: formColumns.linkedTo.id,
          field: 'linkedTo.stringNumber',
          translateCode: formColumns.linkedTo.translateCode,
          type: 'array',
          filterable: true,
          lockable: false,
          trustedTemplate: data => {
            if (data.linkedTo?.length > 0) {
              return data.linkedTo
                .map(form => {
                  const formId = form.id ? form.id : form._id
                  return `<a ui-sref="app.form.display({id:'${htmlWork.htmlEncode(
                    formId
                  )}'})" target="_blank" >#${htmlWork.htmlEncode(
                    form.stringNumber
                  )}</a>`
                })
                .join(', ')
            }
            return '--'
          }
        },
        // LINKED FROM
        {
          uniqueId: formColumns.linkedFrom.id,
          field: 'linkedFrom.stringNumber',
          translateCode: formColumns.linkedFrom.translateCode,
          type: 'array',
          filterable: true,
          lockable: false,
          trustedTemplate: data => {
            if (data.linkedFrom?.length > 0) {
              return data.linkedFrom
                .map(form => {
                  const formId = form.id ? form.id : form._id
                  return `<a ui-sref="app.form.display({id:'${htmlWork.htmlEncode(
                    formId
                  )}'})" target="_blank" >#${htmlWork.htmlEncode(
                    form.stringNumber
                  )}</a>`
                })
                .join(', ')
            }
            return '--'
          }
        }
      ]
    )
    return columns
  }

  return {
    generateStatusTemplate,
    editableFieldTypes,
    performActions,
    isPermit,
    isPermitForReview,
    generateFieldsColumns,
    convertFieldsFromDBToFormly,
    convertDBToForm,
    convertFormToDB,
    generateFormTitle,
    checkForApprovalManagerError,
    getFailureFields,
    generateFormColumns,
    generateAssigneeTemplate,
    generateOwnerTemplate
  }
}

module.exports = FormUtils
