/* global _ */
import { mapOrder } from 'app/helper'

/** @ngInject */
function FormlyHelper (
  $rootScope,
  $translate,
  Field,
  PartAssembly,
  FieldUtilsService,
  MultiTranslateService,
  ResourceUtils,
  getUrlFromObj
) {
  const makeFormlyField = function makeFormlyField (customField, model) {
    let translationPrefix = model.modelName || model.model.name
    if (!_.isUndefined(model.model.options) && model.model.options.i18nPrefix) {
      translationPrefix = model.model.options.i18nPrefix
    }
    const element = {
      key: customField.key,
      defaultValue: customField.default,
      templateOptions: {
        label: $translate.instant(translationPrefix + '.' + customField.key),
        placeholder: $translate.instant(
          translationPrefix + '.' + customField.key
        )
      }
    }
    if (customField.element === 'text' || customField.element === 'number') {
      element.type = 'customInput'
      element.templateOptions.type = customField.type.toLowerCase()
      element.templateOptions.disableScanning = true
    } else if (customField.element === 'boolean') {
      element.type = 'select'
      element.templateOptions.options = [
        { name: true, value: true },
        { name: false, value: false }
      ]
    } else if (
      customField.element === 'select' &&
      customField.type !== 'Array'
    ) {
      element.type = 'select'
      element.templateOptions.options = customField.options.map(option => ({
        name: option,
        value: option
      }))
      element.templateOptions.options.unshift({ name: '', value: null })
    } else if (
      customField.element === 'select' &&
      customField.type === 'Array'
    ) {
      element.type = 'selectWithSearch'
      element.templateOptions.multiple = true
      element.templateOptions.options = customField.options.map(option => ({
        id: option,
        name: option
      }))
    } else if (customField.element === 'labels') {
      element.type = 'chips'
    } else if (customField.element === 'radio') {
      element.type = 'radio'
      element.templateOptions.theme = 'custom'
      element.templateOptions.labelProp = 'name'
      element.templateOptions.valueProp = 'value'
      element.templateOptions.options = customField.options.map(option => ({
        name: option,
        value: option
      }))
    } else if (customField.element === 'date') {
      element.type = 'datePicker'
    }

    return element
  }
  const getCustomFields = function getCustomFields (model, cb = null) {
    const customFields = []
    const modelName = model.modelName || model.model.name
    const properties = _.sortBy(
      $rootScope.appSettings.modelsFields[modelName].properties,
      'weight'
    )
    _.mapKeys(properties, (value, key) => {
      if (value.custom) {
        const formlyField = makeFormlyField(value, model)
        formlyField.templateOptions.onChange =
          cb && typeof cb === 'function'
            ? cb
            : function (value, options) {
                options.validation.errorExistsAndShouldBeVisible = null
                options.formControl.$setValidity('serverError', true)
              }
        customFields.push(formlyField)
      }
    })
    return customFields
  }
  const addCustomValidator = function addCustomValidator (field) {
    if (field.templateOptions.required) {
      field.validators = {
        required: {
          expression: function (viewValue, modelValue) {
            const value = modelValue || viewValue
            return value !== null && value !== undefined
          },
          message: (viewValue, modelValue, scope) => {
            return $translate.instant('Field.VALIDATION.REQUIRED')
          }
        }
      }
    }
  }

  const buildFields = function buildFields (
    staticFields,
    model,
    path = null,
    cb = null
  ) {
    const customFields = getCustomFields(model, cb)
    customFields.forEach(customField => {
      if (!path) {
        staticFields.push(customField)
      } else {
        _.get(staticFields, path).push(customField)
      }
    })
    return staticFields
  }
  const setAllFieldDisableExpression = function setAllFieldDisableExpression ({
    fields,
    fn
  }) {
    fields.forEach(field => {
      fn(field)
      if (field.fieldGroup) {
        setAllFieldDisableExpression({ fields: field.fieldGroup, fn })
      }
    })
  }
  const runExpression = function runExpression ({ fields, fn }) {
    fields.forEach(field => {
      if (field.runExpressions) {
        field.runExpressions()
      }
      if (field.fieldGroup) {
        runExpression({ fields: field.fieldGroup, fn })
      }
    })
  }

  const manipulateOptions = function manipulateOptions (options, type) {
    if (type === 'chooseFromList' || type === 'select') {
      return options.map(option => ({
        id: option.id || option.value,
        name: option.value,
        resourceId: option.resourceId || null,
        color: option.color || null,
        icon: option.color ? 'icon-checkbox-blank-circle' : null
      }))
    }
    if (type === 'multipleChoices' || type === 'radio') {
      return options.map(option => ({
        value: option.id || option.value, // value should be undefiend when current language === defualt language
        name: option.value,
        resourceId: option.resourceId || null
      }))
    }
  }
  const convertToFormlyObject = async function convertToFormlyObject (
    item,
    preFetchedFields = null
  ) {
    let obj = {}
    if (item.type === 'input') {
      obj = {
        key: item.id,
        type: 'customInput',
        className: 'layout-row',
        ngModelAttrs: {
          dir: {
            bound: 'dir',
            attribute: 'dir'
          }
        },
        templateOptions: {
          dir: 'auto',
          hint: item.helpText,
          min: item.min,
          max: item.max,
          helpImage: item.helpImage,
          required: item.required || false,
          pattern: item.pattern || '',
          type: item.subtype,
          label: item.title,
          visionAi: item.visionAi || false,
          aiPrompt: item.aiPrompt || ''
        }
      }
    } else if (item.type === 'textarea') {
      obj = {
        key: item.id,
        type: 'customTextarea',
        className: 'layout-row',
        templateOptions: {
          hint: item.helpText,
          helpImage: item.helpImage,
          rows: 2,
          grow: true,
          label: item.title,
          required: item.required || false,
          visionAi: item.visionAi || false,
          aiPrompt: item.aiPrompt || ''
        }
      }
    } else if (item.type === 'chooseFromList' || item.type === 'select') {
      obj = {
        key: item.id,
        type: 'selectWithSearch',
        className: 'layout-row',
        templateOptions: {
          hint: item.helpText,
          helpImage: item.helpImage,
          multiple: false,
          required: item.required || false,
          label: item.title,
          showVision: true,
          allowEmpty: true,
          options: manipulateOptions(item.options, item.type)
        }
      }
      addCustomValidator(obj)
    } else if (item.type === 'multipleChoices' || item.type === 'radio') {
      // radio
      obj = {
        key: item.id,
        type: 'radioMaterial',
        className: 'layout-column',
        templateOptions: {
          hint: item.helpText,
          helpImage: item.helpImage,
          layout: item.direction === 'vertical' ? 'column' : 'row',
          required: item.required || false,
          label: item.title,
          options: manipulateOptions(item.options, item.type)
        }
      }
    } else if (item.type === 'checkbox') {
      obj = {
        key: item.id,
        type: 'checkbox',
        defaultValue: false,
        className: 'layout-column',
        templateOptions: {
          hint: item.helpText,
          helpImage: item.helpImage,
          required: item.required || false,
          label: item.title
        }
      }
    } else if (item.type === 'button' || item.type === 'upload') {
      const objectKey = Math.floor(Math.random() * (999999 - 1)) + 1
      obj = {
        key: item.id,
        type: 'upload',
        className: 'layout-column',
        templateOptions: {
          object: `${objectKey}Object`,
          hint: item.helpText,
          helpImage: item.helpImage,
          parserInstantRun:
            typeof item.parserInstantRun === 'undefined'
              ? true
              : item.parserInstantRun,
          label: item.title,
          caption: item.caption,
          runFileParser: item.runFileParser || false,
          fileParser: item.fileParser || null,
          displaytype: 'upload',
          keepOriginal: true,
          required: item.required || false,
          mode: item.mode || 'full',
          shouldUpdateObject: true,
          keyIs: 'id',
          onClick: function (value, options) {},
          onFileSelect: function (result) {}
        }
      }
    } else if (item.type === 'dateTimePicker' || item.type === 'datePicker') {
      obj = {
        key: item.id,
        type: item.type,
        className: 'layout-column',
        templateOptions: {
          hint: item.helpText,
          staticLabel: true,
          helpImage: item.helpImage,
          required: item.required || false,
          label: item.title
        }
      }
      if (item.minDate) {
        obj.templateOptions.minDate = item.minDate
      }
      if (item.maxDate) {
        obj.templateOptions.maxDate = item.maxDate
      }
    } else if (item.type === 'subForm') {
      let fields = []
      if (preFetchedFields) {
        fields = preFetchedFields.filter(f => item.fieldIds.includes(f.id))
      } else {
        fields = await Field.find({
          filter: {
            where: { id: { inq: item.fieldIds } },
            deleted: true,
            include: ['resource']
          }
        }).$promise
      }
      fields = fields.map(field => {
        return MultiTranslateService.getForView(
          Field,
          $rootScope.currentLang,
          field
        )
      })
      const orderFields = mapOrder(fields, item.fieldIds, 'id')
      const formlyFields = []
      for (let i = 0; i < orderFields.length; i++) {
        const formlyField = await convertToFormlyObject(
          orderFields[i],
          preFetchedFields
        )
        formlyFields.push(formlyField)
      }
      obj = {
        key: item.id,
        type: 'subForm',
        templateOptions: {
          layout: 'column',
          hint: item.helpText,
          editable: true,
          label: item.title,
          required: false,
          caption: item.caption,
          originalFields: fields,
          minRequiredRows: item.minRequiredRows || 0,
          fields: formlyFields,
          fieldIds: item.fieldIds
        }
      }
    } else if (item.type === 'imageBasedForm') {
      let fields = await Field.getFieldsWithInnerFields({
        fieldIds: item.fieldIds
      }).$promise
      fields = fields.map(field => {
        return MultiTranslateService.getForView(
          Field,
          $rootScope.currentLang,
          field
        )
      })
      const orderFields = mapOrder(fields, item.fieldIds, 'id')
      obj = {
        key: item.id,
        type: 'imageBasedForm',
        templateOptions: {
          layout: 'row',
          label: item.title,
          editable: true,
          required: false,
          resourceId: item.resourceId,
          fieldObject: item,
          originalFields: orderFields,
          images: item.images,
          hint: item.helpText,
          fieldIds: item.fieldIds
        }
      }
    } else if (item.type === 'lookupSelect') {
      switch (item.tableType) {
        case FieldUtilsService.lookupTypes.MODELS:
          obj = {
            key: item.id,
            type: 'modelSelect',
            templateOptions: {
              hint: item.helpText,
              helpImage: item.helpImage,
              required: item.required || false,
              label: item.title,
              tableId: item.tableId,
              tableType: item.tableType,
              findMethod: PartAssembly.find,
              mapObject: { id: 'number', name: 'number', displayName: 'name' },
              baseFilterObject: {
                where: {},
                fields: {
                  number: true,
                  name: true
                }
              },
              allowEmpty: true
            }
          }
          break
        case FieldUtilsService.lookupTypes.NPT:
          obj = {
            key: item.id,
            type: 'lookupSelect',
            templateOptions: {
              hint: item.helpText,
              helpImage: item.helpImage,
              required: item.required || false,
              label: item.title,
              tableId: item.tableId,
              tableType: item.tableType,
              lookupField: item.lookupField,
              fieldIds: item.fieldIds,
              allowEmpty: true
            }
          }
          break
        default:
          console.error('There is no table type of', item.tableType)
      }
      addCustomValidator(obj)
    } else if (item.type === 'gpsInput') {
      obj = {
        key: item.id,
        type: item.type,
        templateOptions: {
          hint: item.helpText,
          helpImage: item.helpImage,
          required: item.required || false,
          label: item.title
        }
      }
    } else if (item.type === 'selectUser') {
      obj = {
        key: item.id,
        type: item.type,
        templateOptions: {
          hint: item.helpText,
          helpImage: item.helpImage,
          required: item.required || false,
          label: item.title,
          multiple: item.multiple || false,
          showConstants: false,
          allowEmpty: true
        }
      }
      if (!item.multiple) {
        addCustomValidator(obj)
      }
    } else if (item.type === 'tinymce') {
      obj = {
        key: item.id,
        type: item.type,
        templateOptions: {
          hint: item.helpText,
          helpImage: item.helpImage,
          required: item.required || false,
          label: item.title,
          uploadOptions: { hidden: true, direct: true },
          tinymceOptions: {
            removeMention: true
          }
        }
      }
    }

    if (item.resource) {
      obj.templateOptions.resource = addResourceParametersToField(item.resource)
    }
    if (!_.isUndefined(item.translations)) {
      obj.translations = item.translations
    }

    if (!_.isUndefined(item.fallbackStatus)) {
      obj.fallbackStatus = item.fallbackStatus
    }

    if (!_.isUndefined(item.placeholder) && item.placeholder !== '') {
      obj.templateOptions.placeholder = item.placeholder
    }

    if (
      !_.isNil(item.defaultValue) &&
      item.defaultValue !== false &&
      item.defaultValue !== ''
    ) {
      if (item.type === 'dateTimePicker' || item.type === 'datePicker') {
        obj.defaultValue = new Date()
      } else if (item.type === 'input' && item.subtype === 'number') {
        obj.defaultValue = parseFloat(item.defaultValue)
      } else {
        obj.defaultValue = item.defaultValue
      }
    }

    return obj
  }
  const addResourceParametersToField = function addResourceParametersToField (
    resource
  ) {
    const obj = resource
    let type = 'image'
    if (resource.type.startsWith('video')) {
      type = 'video'
    }
    obj.resourceType = type
    obj.url = getUrlFromObj(resource)
    obj.videoSrc = ResourceUtils.generateHLSSrc(resource)
    if (resource.posters && resource.posters.length) {
      const largePoster = resource.posters.find(p => p.name === 'large')
      if (largePoster) {
        const posterURL =
          '/api/containers/' +
          resource.container +
          '/download/' +
          largePoster.fileName
        obj.posterURL = posterURL
      }
    }
    return obj
  }
  return {
    buildFields,
    runExpression,
    getCustomFields,
    setAllFieldDisableExpression,
    convertToFormlyObject,
    addResourceParametersToField,
    manipulateOptions
  }
}

module.exports = FormlyHelper
